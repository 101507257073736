<template>
    <div>
        <!-- 头部logo -->
        <moveHeadLogo @navigation="navigation"></moveHeadLogo>

        <!-- 头部导航 -->
        <moveNavigation
            :headTitle="headTitle"
            :headImg="headImg"></moveNavigation>

        <el-row class="presidentmessage">
            <el-row class="president-imgdata">
                <el-row v-if="isimg == true">
                    <el-col
                        :span="16"
                        :offset="4">
                        <img
                            :src="leaderNotLoadImage"
                            :onload="onLoadImg(pictureUrl)"
                            class="president-img" />
                    </el-col>
                </el-row>
                <el-row>
                    <el-row
                        v-for="(item, index) in list"
                        :key="index"
                        class="leadership">
                        <el-row class="leadership-englishname">
                            {{ item.leadName }}
                        </el-row>
                        <el-row class="leadership-post">
                            {{ item.leadPost }}
                        </el-row>
                        <el-row>
                            <el-image
                                class="news-chainimg"
                                src="/home/chain.png"></el-image>
                        </el-row>
                    </el-row>
                </el-row>
            </el-row>
        </el-row>

        <!-- 菜单导航 -->
        <moveMeauDialog
            :meauValue="navigationvalue"
            @meauFn="menu"></moveMeauDialog>

        <!-- 底部导航 -->
        <moveBottomNavigation></moveBottomNavigation>
    </div>
</template>

<script>
import { selectPictureListById, selectLeadershipById } from '@/api/index.js'

// 头部组件
import moveHeadLogo from '@/components/move/moveHeadLogo.vue'
// 菜单导航
import moveMeauDialog from '@/components/move/moveMeauDialog.vue'
// 头部导航
import moveNavigation from '@/components/move/moveNavigation.vue'
// 底部导航
import moveBottomNavigation from '@/components/move/moveBottomNavigation.vue'
export default {
    data() {
        return {
            // 头部导航标题
            headTitle: 'EXECUTIVES',
            // 头部导航图片
            headImg: '/index/message.png',
            // 导航值
            navigationvalue: false,

            list: [],
            pictureUrl: '/Mimage/375_500.jpg',
            isimg: true,
            leaderNotLoadImage: '/Mimage/375_500.jpg' //领导图片未加载出来
        }
    },
    components: {
        moveHeadLogo,
        moveNavigation,
        moveBottomNavigation,
        moveMeauDialog
    },
    created() {
        this.imgList()
    },
    methods: {
        // 头部传来的数据
        navigation(v) {
            this.navigationvalue = v
            console.log(this.navigationvalue, '头部传递菜单导航')
        },
        // 遮罩层传回来的数据
        menu(v) {
            this.navigationvalue = v
        },

        // 领导层图片
        imgList() {
            selectLeadershipById({
                chnanelType: 2,
                isIndex: 0,
                moduleType: 'MODULE_LEADERSHIP'
            }).then(response => {
                this.list = response.data.data
                if (this.list[0].pictureUrl == null || this.list[0].pictureUrl == '') {
                    this.isimg = false
                } else {
                    this.isimg = true
                    this.pictureUrl = response.data.data[0].pictureUrl
                }
            })
        },
        // 图片加载完成时触发
        onLoadImg(imgSrc) {
            return 'this.src=' + '"' + imgSrc + '";this.οnlοad=null'
        }
    }
}
</script>

<style scoped>
.presidentmessage {
    width: 100%;
}
.president-imgdata {
    width: 100%;
    position: relative;
}
.president-img {
    width: 375px;
    height: 500px;
}
.president-data {
    height: 50px;
    background-color: rgba(12, 12, 12, 0.5);
    position: absolute;
    bottom: 3px;
    line-height: 50px;
    color: white;
    font-size: 20px;
    text-align: center;
    padding-left: 3px;
    padding-right: 3px;
}
.leadership {
    width: 100%;
    border-bottom: solid 2px #ccc;
    margin-bottom: 20px;
}
.leadership-englishname {
    margin-top: 64px;
    height: 100px;
    font-size: 35px;
    font-family: Roboto !important;
    color: #505050;
    line-height: 100px;
}
.leadership-post {
    font-size: 23px;
    line-height: 40px;
}
.news-chainimg {
    width: 128px;
    height: 13px;
    margin: 20px 0px;
}
</style>
